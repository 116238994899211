@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
}

.custom-shape-divider-top-1653921408 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  .custom-shape-divider-top-1653921408 .shape-fill {
    fill: white;
  }
  .custom-shape-divider-bottom-1653921561 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .custom-shape-divider-bottom-1653921638 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .custom-shape-divider-bottom-1653921638 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
  }
  
  .custom-shape-divider-bottom-1653921638 .shape-fill {
    fill: #ffffff;
  }

/* Contact page */
.custom-shape-divider-bottom-1692553175 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1692553175 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 71px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1692553175 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-top-1692552863 {
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1692552863 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 62px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1692552863 .shape-fill {
  fill: #FFFFFF;
}